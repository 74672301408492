/* styles.css */
.markdown-table {
  width: 100%;
  border-collapse: collapse;
}

.markdown-table th,
.markdown-table td {
  border: 1px solid #6a6a6a;
  padding: 8px;
}

.markdown-table th {
  background-color: #f2f2f2;
  text-align: left;
}